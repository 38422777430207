<template>
  <div class="wavePeak">
    <div class="wp-header">
      <span>每日人数波峰图</span>
      <span>统计时段&nbsp;
        <el-date-picker
          v-model="timeVal"
          type="daterange"
          :clearable="false"
          @change="selectTimeArea"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </span>
      <span>
        <img src="@/assets/img/warn-1.png" alt="">
        <span>最多可选择31天的统计范围</span>
      </span>
    </div>
    <div class="wp-charts">
      <v-chart class="chart-school" :option="option" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'wavePeak',
  data() {
    return {
      timeVal: null,
      formInline: {
        startTime: null,
        endTime: null
      },
      pickerOptions: {
        disabledDate(v) {
          // 4、日期限制只能选当天和前30天
          let limitDate =
            v.getTime() < new Date().getTime() - 31 * 86400000 ||
            v.getTime() >= Date.now() + 8.64e7;
          // 86400000:一天的毫秒
          return limitDate;
        },
      },
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          bottom: '10%',
          left: 'center'
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '4%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '在线人数',
            type: 'line',
            areaStyle: {},
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '课件学习人数',
            type: 'line',
            areaStyle: {},
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '考试人数',
            type: 'line',
            areaStyle: {},
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '直播观看人数',
            type: 'line',
            areaStyle: {},
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          }
        ]
      }
    }
  },
  mounted() {
    this.getNowTime()
  },
  methods: {
    // 获取当天及前31天的时间戳
    getNowTime() {
      let time = new Date()
      time.setHours(23)
      time.setSeconds(59)
      time.setUTCMinutes(59)
      time = this.$way.dateReturnTimestamp(time)
      let oldTime = time - 2678399000
      this.timeVal = [oldTime, time]
      this.formInline.startTime = oldTime
      this.formInline.endTime = time
      this.getPlatformDailyDataEvent()
    },
    // 获取其时间段
    selectTimeArea() {
      let differValue = Math.round((this.timeVal[1]-this.timeVal[0])/86400000)
      if (differValue > 31) {
        this.Warn('最多可选择31天的统计范围')
        return
      }
      this.formInline.startTime = this.timeVal ? this.timeVal[0] : null
      this.formInline.endTime = this.timeVal ? this.timeVal[1] : null
      this.getPlatformDailyDataEvent()
    },
    getPlatformDailyDataEvent() {
      this.$request.getPlatformDailyData(this.formInline).then(res => {
        if(res.data.code == 0) {
          this.option.xAxis[0].data = []
          this.option.series[0].data = []
          this.option.series[1].data = []
          this.option.series[2].data = []
          this.option.series[3].data = []
          res.data.data.forEach(element => {
            this.option.xAxis[0].data.push(element.date)
            this.option.series[0].data.push(element.onlineCount)
            this.option.series[1].data.push(element.studyCount)
            this.option.series[2].data.push(element.examCount)
            this.option.series[3].data.push(element.liveCount)
          });
          this.option.dataZoom = [
            {
              show: this.option.xAxis[0].data.length > 5 ? true : false,
              // show: true,
              height: 12,
              xAxisIndex: [0],
              bottom: "4%",
              start: 0,
              end: 100,
              handleIcon:
                "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
              handleSize: "110%",
              handleStyle: {
                color: "#d3dee5",
              },
              // textStyle: {
              //   color: "#666",
              // },
              textStyle: false,
              borderColor: "#90979c",
            }
          ]
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
  .wavePeak {
    background-color: #fff;
    box-sizing: border-box;
    margin: 20px 0;
    .wp-header {
      display: flex;
      align-items: center;
      padding: 30px;
      border-bottom: 1px solid #EFEFEF;
      & > span:nth-of-type(1) {
        font-size: 26px;
        color: #16222E;
        margin-right: 24px;
      }
      & > span:nth-of-type(2) {
        font-size: 14px;
        color: #363840;
        /deep/ .el-input__inner {
          height: 34px;
        }
        /deep/ .el-input__icon, /deep/.el-range-separator {
          line-height: 26px;
        }
      }
      & > span:nth-of-type(3) {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666666;
        img {
          width: 13px;
          margin-left: 24px;
        }
      }
    }
    .wp-charts {
      padding: 30px;
      .chart-school {
        height: 360px;
        width: 100%;
        display: inline-block;
      }
    }
  }
</style>